import { observable } from "mobx";
import { autobind } from "core-decorators";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import {
	BarOrEmployeeFinance,
	Employee,
	EmployeeV2,
	GetEmployeeTypes,
	Permission,
	Role,
} from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import { fetchModel } from "#resources/helpers/fetch-model";
import i18n from "#i18n/index";

const t = i18n.t;

@autobind
export class EmployeeStore {
	public clean() {
		this.employees = [];
		this.roles = [];
		this.permissions = [];
	}

	// EMPLOYEES

	@observable
	public employees: EmployeeV2[] = [];

	public getEmployees = new fetchModel<{}, EmployeeV2[]>({
		fnPromise: args => enterprise.getEmployeesV2(args),
		onSuccess: rtn => (this.employees = rtn),
		onError: err => showErrorNotification(err.message),
	});

	public getEmployee = new fetchModel<
		{
			employeeId: string;
		},
		Employee
	>({
		fnPromise: args => enterprise.getEmployee(args),
		onError: err => showErrorNotification(err.message),
	});

	// EMPLOYEE ROLES

	@observable
	public roles: Role[] = [];

	public getAllRoles = new fetchModel<{}, Role[]>({
		fnPromise: args => enterprise.getAllRoles(args),
		onSuccess: roles => (this.roles = roles),
		onError: err => showErrorNotification(err.message),
	});

	public createRole = new fetchModel<
		{
			name: string;
			permissions: string[];
		},
		Role
	>({
		fnPromise: args => enterprise.createRole(args),
		onSuccess: () => showSuccessNotification(t("store:employeeStore.createRole")),
		onError: err => showErrorNotification(err.message),
	});

	public deleteRole = new fetchModel<
		{
			slug: string;
		},
		void
	>({
		fnPromise: args => enterprise.deleteRole(args),
		onSuccess: () => showSuccessNotification(t("store:employeeStore.deleteRole")),
		onError: err => showErrorNotification(err.message),
	});

	public updateRole = new fetchModel<
		{
			slug: string;
			name: string;
			permissions: string[];
		},
		Role
	>({
		fnPromise: args => enterprise.updateRole(args),
		onSuccess: () => showSuccessNotification(t("store:employeeStore.updateRole")),
		onError: err => showErrorNotification(err.message),
	});

	// EMPLOYEE PERMISSIONS

	@observable
	public permissions: Permission[] = [];

	public getAllPermissions = new fetchModel<{}, Permission[]>({
		fnPromise: async args => {
			const allPermissions = await enterprise.getAllPermissions(args);
			const permissionsToHide = [
				"authorizeHawkerBreak",
				"manageHawkerCycle",
				"refundViaPix",
			];

			return allPermissions.filter(p => !permissionsToHide.includes(p.slug));
		},
		onSuccess: permissions => (this.permissions = permissions),
		onError: err => showErrorNotification(err.message),
	});

	// EMPLOYEE REPORTS

	public getEstimatedFinanceDetailsByEmployeeAtEvent = new fetchModel<
		{
			eventId: string;
		},
		BarOrEmployeeFinance[]
	>({
		fnPromise: args => enterprise.getEstimatedFinanceDetailsByEmployeeAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getEmployeeTypes = new fetchModel<{}, GetEmployeeTypes[]>({
		fnPromise: args => enterprise.getEmployeeTypes(args),
		onError: err => showErrorNotification(err.message),
	});
}
