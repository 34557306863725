export const deliveredProductsByBar = {
	tabTitle: "Products delivered by bar",
	filename: "delivered-products",
	selectedBars: "Selected bars",
	noSelectedBar: "Select at least one bar to view the report",
	table: {
		total: "Total",
		name: "Name",
		count: "Units Sold",
		fracionalCount: "Fractional Quantity",
		fracCount: "Fractional Quantity",
		unitCost: "Unitary value",
		proportionalValue: "Proportional Value",
		subtotal: "Subtotal",
		totalDiscount: "Discounts",
		totalVat: "Total VAT",
		valueVat: "VAT",
		totalValue: "Total value",
		category: "Category",
	},
	bar: "Pub",
	selectPlaceholder: "Select Bar(s)",
	all: "All",
	selectVisualization: "Select view",
	byBars: "By bars",
	transactionType: "Transaction type",
	bars: "Bars",
	productMasterKinds: "Product types",
	applyFilters: "Apply filters",
	total: "Total",
	emptyState: {
		withoutFilters: {
			title: "No filters applied",
			description: "Select at least one bar to generate a report.",
		},
		anyResults: {
			title: "No results found",
			description: "No results matching the applied filters were found!",
		},
	},
	totalizer: {
		totalUnitsSold: "Total units sold",
		totalInDiscounts: "Total in discounts",
		totalValueSold: "Total value sold",
	},
};
